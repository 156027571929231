import Vue from 'vue'
import Vuex from 'vuex'
import {httpService} from "@/services/http";
import {constants} from "@/services/constants";
import {schoolService} from "@/services/school";

Vue.use(Vuex)

export default new Vuex.Store({
    state:{
        AllSchool:'',//所有学校
        SchoolList:'',//分类后的所有学校
        blogsList:'',//所有资讯
        webSocketMsg: ''
    },

    getters:{
        webSocketMsg: state => state.user.webSocketMsg
    },

    mutations:{
        AllSchool(state,data){
            console.log(1,data)
            state.AllSchool = data.body
        },
        AllBlogs(state,data){
            state.blogsList = data.body
        },
        // 存储websocket推送的消息
        SET_WS_MSG: (state, msg) => {
            state.webSocketMsg = msg
        }
    },

    actions:{
        getAllSchool(ctx){
            httpService.get(constants.SCHOOL_LIST).then((data)=>{
                ctx.commit('AllSchool',data)
            })
        },
        getAllBlogs(ctx){
            httpService.get(constants.BLOGS).then((data)=>{//获取资讯信息
                ctx.commit('AllBlogs',data)
            })
        }
    }
})
