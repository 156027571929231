<template>
  <div class="successCard">
    <div class="tag">{{ tag }}</div>
    <div class="img">
      <el-image :src="img" style="width: 100%; height: 100%" :preview-src-list="[img]"></el-image>
<!--      <img height="100%" width="100%" :src="img" alt="">-->
    </div>
    <div class="content">
      <div class="content-left">
        <img src="../../assets/myImage/tou.png" alt="">
      </div>
      <div class="content-right">
        <h4>{{ school }}</h4>
        <div class="zhuanye">{{ zhuanye }}</div>
        <div>{{ name }} ｜ {{ time }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "successCard",
  props:['img','name','school','time','zhuanye','tag'],
  created() {
    console.log(this.img)
  }
}

</script>

<style lang="scss" scoped>
//*{
//  outline: 1px solid red;
//}
.successCard{
  //height: 20vw;
  width: 19vw;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  .tag{
    position: absolute;
    top:1.5vh;
    left: 1.5vw;
    color: white;
    background-color: #007DD1;
    border-radius: 10px;
    text-align: center;
    width: 5vw;
    padding: .5vh;
    font-weight: 600;
  }
  .content{
    padding: 0 0 .5vw 1vw;
    display: flex;
    margin: .7vw 0 0;
    //height: 12vh;
    .content-left{
      height: 2.5vw;
      width: 3.5vw;
      img{
        height: 100%;
        width: 100%;
      }
    }
    .content-right{
      padding-left: 1vw;
      width: 90%;
    }
  }
}
.img{
  //border-radius: 5px;
  //padding-top: .5vh;
  //background-color: whitesmoke;
  text-align: center;
  height: 23vw;
  width: 100%;
}
.zhuanye{
  opacity: .6;
}
h4{
  margin-bottom: 0;
}
</style>
