
import vue from 'vue'
import VueResource from 'vue-resource'
vue.use(VueResource);

var httpService = {
    
    get: async function (url) {
        vue.http.headers.common['Authorization'] = '92697bbf-bc6e-4b66-a446-89aed3f30b4f'
        return new  Promise ((resolve, reject) => {
            vue.http.get(url)
                .then(
                    (success) => {
                        resolve(success);
                    },
                    (error)=>{
                        resolve(error);
                    }
                )
        })
    },
    post: async function (url) {
        vue.http.headers.common['Authorization'] = '92697bbf-bc6e-4b66-a446-89aed3f30b4f'
        return new  Promise ((resolve, reject) => {
            vue.http.post(url)
                .then(
                    (success) => {
                        resolve(success);
                    },
                    (error)=>{
                        resolve(error);
                    }
                )
        })
    },
}




export {
    httpService
};