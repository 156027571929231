import Vue from 'vue'

var baseUrl = "https://158-liuxue-p.study65.com"
//var baseUrl = "http://127.0.0.1:8000"

var constants = {
    BASE_URL : baseUrl,
    FORM:baseUrl + "/postMpStudentEnquiry//",
    MEDIA_URL: baseUrl + "/client-static/",
    WECHAT_LOGIN: baseUrl + "/api/wechatLogin/",
    WECHAT_UPDATE_USERINFO: baseUrl + "/api/wechatUserinfoUpdate/",
    EXAM_LIST: baseUrl + "/api/exams/",
    SCHOOL_LIST: baseUrl + "/api/schools/",
    SUBJECT_LIST: baseUrl + "/api/subject/",
    APPLICATION: baseUrl + "/api/applications/",
    CAR_FIVE_SEATS: baseUrl + "/api/carTypes/seats_5/",
    CAR_TYPE: baseUrl + "/api/carTypes/",
    SCHOOL_DETAIL: baseUrl + "/api/schoolDetail/",
    CAR_SEVEN_SEATS: baseUrl + "/api/carTypes/seats_7/",
    SUBJECT_TYPE: baseUrl + "/api/subjectType/",
    SUBJECT_SUBTYPE: baseUrl + "/api/subjectTypeSub/",
    SUBJECT_LEVEL: baseUrl + "/api/subjectLevel/",
    SCHOOL_TYPE: baseUrl + "/api/schoolTypes/",
    EXAM_LIST_PHASE: baseUrl + "/api/exams/get_exams_by_phase/",
    ALL_EXAM_LIST: baseUrl + "/api/exams/all_exams/",
    SUCCESS_CASE: baseUrl + "/api/successCases/",
    LIST_SCHOOL: baseUrl + "/api/listSchools/",
    ENCYCLOPEDIA:baseUrl + "/api/encyclopedia/",
    ABROAD_PROGRAM:baseUrl + "/api/abroadProgram/",
    PICKUP_ORDER: baseUrl + "/api/airportPickup/",
    RESERVE_SCHOOL: baseUrl + "/api/reserveSchoolOrder/",
    VACCINE: baseUrl + "/api/vaccine/",
    UPLOAD: baseUrl + "/api/uploadImage/",
    SCHOOL_LOWAGE: baseUrl + "/api/lowAgeSchoolRecommend/",
    SCHOOL_PRIVATE: baseUrl + "/api/privateSchoolRecommend/",
    HEADER_CAROUSELS1: baseUrl + "/api/webHeaderCarousels/",
    // HEADER_CAROUSELS: baseUrl + "/api/headerCarousels/",
    REQUEST_PAYMENT: baseUrl + "/api/requestPayment/",
    SCHOOL_PUBLIC: baseUrl + "/api/publicSchoolRecommend/",
    STUDENT_FEEDBACK: baseUrl + "/api/studentFeedback/",
    sagepathsAgent:baseUrl + "/api/sagepathsAgent/",
    encyclopedia:baseUrl + "/api/encyclopedia/",
    BLOGS:baseUrl + "/api/blogs/",
    BLOGS_TYPES:baseUrl + "/api/blogCategory/",
    HEADER_CAROUSELS:baseUrl + '/api/headerCarousels/',
// {baseurl}/api/searchInfo/
    SEARCH:baseUrl + '/api/searchInfo/'

}

export {
    constants
};
