<template>
  <div>
    <div class="card-wrapper">
      <img style="border-radius: 50%" :src="avatar" alt="avatar" class="avatar">
      <div class="name">{{name}}</div>
      <!--    <div class="position">{{position}}</div>-->
      <div class="btn-wrapper">
        <el-button @click="dialogVisible = true" type="text">
          <b-button class="consult-btn" size="sm" variant="primary">马上咨询</b-button>
        </el-button>
      </div>
      <div class="intro">{{intro}}</div>


    </div>
    <el-dialog
        append-to-body="true"
        title="微信扫码咨询"
        :visible.sync="dialogVisible"
        width="20%"
        center
        :before-close="handleClose">
      <div style="text-align: center">
        <div>扫描下方二维码咨询该顾问老师！</div>
        <img width="200" height="200" :src="qr" alt="">
      </div>
    </el-dialog>
  </div>
</template>

<script>
    import {httpService} from "@/services/http";
    import {constants} from "@/services/constants";
    export default {
      name: 'TeacherCard',
      data(){
        return{
          dialogVisible:false
        }
      },
			props: {
        avatar: String,
        name: String,
        position: String,
        intro: String,
        consultUrl: String,
        sm: Boolean,
        qr:String,

      },
      methods: {
        handleClose(done) {
          this.dialogVisible = false
        }
      }

    }
</script>

<style lang="scss" scoped>
.card-wrapper:hover{
  overflow-y: auto;
}
  .card-wrapper {
    border: 1px solid #E7E7EA;
    box-shadow: 0 5px 14px rgba(8, 15, 52, 0.04);
    border-radius: 20px;
    padding: 1vw 1.2vw 1vw;
    position: relative;
    text-align: center;
    height: 35vw;
    width: 16vw;
    margin-top: 1vw;
    overflow: hidden;
    .avatar {
      //position: absolute;
      height: 100px;
      width: 100px;
    }

    .name {
      color: #170F49;
      font-size: 1.2vw;
      font-weight: bold;
    }

    .position {
      color: #170F49;
      margin-top: 10px;
      font-size: 18px;
      font-weight: bold;
    }

    .intro {
      font-size: .8vw;
      margin-top: 10px;
      font-family: 'DM Sans';
      font-weight: 400;
      color: #838383;
    }

    .btn-wrapper {
      //position: absolute;
      //bottom:1vw;
      //right: 2vw;
      .consult-btn {
        cursor: pointer;
        background-color: #007DD1;
        color: #FFF;
      }
    }

  }
</style>
