<template>
  <!--====================  header area ====================-->
  <div class="header-area">
    <div class="header-area__desktop header-area__desktop--default">

      <!--=======  header info area  =======-->
      <div class="header-info-area">
        <div class="head1" style="width: 14vw;padding-left: 5vw">
          <div style="height: 6.5vw;width: 6.5vw;cursor: pointer" @click="toHome">
            <img width="100%" height="100%" src="../../public/assets/logo3.png" alt="">
          </div>
        </div>
        <div class="head2">
          <select v-model="val">
            <option value="院校">院校</option>
            <option value="专业">专业</option>
            <option value="资讯">资讯</option>
          </select>
          <input v-model="val1" type="text" placeholder="请输入关键词搜索">
          <div @click="toShowSearch(val,val1)" class="sou">
            <img src="../assets/myImage/Vector.png" alt="">
          </div>
        </div>
        <div class="head3">
          <div style="height: 1.9vw">
            <img height="100%" src="assets/img/contact.svg" alt="">
          </div>
        </div>
        <div class="head4">
          <span>+65 60150667 （新加坡） |  +86 13816876870 （中国）</span>
        </div>
      </div>
      <!--=======  End of header info area =======-->

      <!--=======  header navigation area  =======-->
      <fixed-header>
        <div class="header-navigation-area default-bg" style="margin: 0">
          <div>
            <div class="row">
              <div class="col-lg-12">
                <!-- header navigation -->
                <div>
                  <b-navbar style="height: 4vw;background-color:#007DD1;justify-content: space-around;font-size: 1.1vw" toggleable="lg" type="info">
                    <b-navbar-nav style="width: 40%;justify-content: space-between;color: white">
                      <b-nav-item href="/">首页</b-nav-item>

                      <!-- Navbar dropdowns -->
                      <b-nav-item-dropdown text="新加坡院校">
<!--                        <b-dropdown-item href="#">EN</b-dropdown-item>-->
<!--                        <b-dropdown-item href="#">ES</b-dropdown-item>-->
<!--                        <b-dropdown-item href="#">RU</b-dropdown-item>-->
<!--                        <b-dropdown-item href="#">FA</b-dropdown-item>-->
                        <b-dropdown-item v-for="item of schoolTypes" :key="item.id" :href="'/schools?t='+item.type">
                                                        <router-link :to="'/schools?t='+item.type">{{
                                                          item.type
                                                        }}</router-link>
                                                      </b-dropdown-item>
                      </b-nav-item-dropdown>
                      <b-nav-item href="/project">留学专业</b-nav-item>
                      <b-nav-item href="/k12/intro">低龄留学</b-nav-item>
                      <b-nav-item href="/news">热点新闻</b-nav-item>
                      <b-nav-item href="/contact">关于我们</b-nav-item>

<!--                      <b-nav-item href="/web">即时通讯测试</b-nav-item>-->

<!--                      <b-nav-item-dropdown text="User" right>-->
<!--                        <b-dropdown-item href="#">Account</b-dropdown-item>-->
<!--                        <b-dropdown-item href="#">Settings</b-dropdown-item>-->
<!--                      </b-nav-item-dropdown>-->
                    </b-navbar-nav>
                    <b-navbar-nav style="width: 10%;justify-content: space-around">
                      <b-nav-item>
                        <el-button type="text" @click="dialogVisible1 = true">
                          <img height="90%" width="90%" src="../assets/myImage/wechat.png" alt="" title="添加微信">
                        </el-button>
                      </b-nav-item>
                      <b-nav-item>
                        <el-button type="text" @click="dialogVisible2 = true">
                          <img height="90%" width="90%" src="../assets/myImage/phone.png" alt="" title="查看电话">
                        </el-button>
                      </b-nav-item>
                    </b-navbar-nav>
                  </b-navbar>
                </div>

              </div>
            </div>
          </div>
        </div>
      </fixed-header>
      <!--=======  End of header navigation area =======-->
    </div>
    <el-dialog
        title="微信咨询"
        :visible.sync="dialogVisible1"
        width="20%"
        :before-close="handleClose"
        center
    >
      <div style="text-align: center">
        <p>您可以添加微信号：<strong>EduSg11</strong> 或 扫描下方二维码直接咨询顾问老师！</p>
        <div>
          <img src="../assets/myImage/guwen.jpg" width="200" height="200" alt="">
        </div>
      </div>
    </el-dialog>
    <el-dialog
        title="电话咨询"
        :visible.sync="dialogVisible2"
        width="20%"
        center
        :before-close="handleClose"
    >
      <div style="text-align: center">
        <div>请拨打下方电话资讯顾问老师！</div>
        <div><strong>电话：+86 13816876870</strong></div>
        <div>
          <img src="../assets/myImage/ter.jpeg" width="200" height="180" alt="">
        </div>
      </div>
    </el-dialog>
  </div>
  <!--====================  End of header area  ====================-->
</template>

<script>
import FixedHeader from "vue-fixed-header";
import { schoolService } from "../services/school";
import {httpService} from "@/services/http";
import {constants} from "@/services/constants";
import axios from "axios";
export default {
  components: {
    FixedHeader,
  },
  data() {
    return {
      schoolTypes: '',
      swiperData:'',
      dialogVisible1:false,
      dialogVisible2:false,
      val:'院校',
      val1:'',
      info:''
    };
  },
  created() {
    // 获取所有学校类型
    httpService.get(constants.SCHOOL_TYPE).then((data)=>{
      this.schoolTypes = data.body
      console.log(this.schoolTypes)
    })
    // httpService.get(constants.SCHOOL_LIST + '?name=南洋管理学院').then((data) => {
    //   console.log(1,data)
    // })
  },
  methods: {
    toHome(){
      this.$router.push({path:'/'})
    },
    handleClose() {
      this.dialogVisible1 = false
      this.dialogVisible2 = false
    },
    toShowSearch(val,val1){
      if (val1 === ''){
        alert('请输入信息')
      }else {
        this.$router.push({path:'/showSearch',query:{val,val1}})
      }
    }
  }
};
</script>

<style lang="scss" scoped>
//.header-area *{
//  outline: 1px solid red;
//}
//*{
//  outline: 1px solid red;
//}
ul li{
  list-style-type: none;
}

.el-select .el-input {
  width: 130px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.header-info-area {
  text-align: center;
  height: 6.5vw;
  width: 80%;
  margin: 0px auto;
  display: flex;
  //justify-content: space-between;
  align-items: center;
  .head2{

    position: relative;
    width: 33vw;
    input{
      width: 24vw;
      padding-left: 4.5vw;
      height: 2vw;
      border-radius: 40px;
      border: 1px solid #C4C4C4;
      font-size: 1vw;
    }
    select{
      height: 2vw;
      width: 5vw;
      border-radius: 40px 0 0 40px;
      position: absolute;
      top:0;
      left:3vw;
      padding-left: 1vw;
      border: 1px solid #C4C4C4;
      font-size: .8vw;
    }
    .sou{
      background-color: #32435C;
      position: absolute;
      border-radius: 40px;
      height: 2vw;
      width: 2.4vw;
      top: 0;
      left: 26.5vw;
      text-align: center;
      line-height: 2vw;
      cursor: pointer;
      img{
        height: 50%;
        width: 40%;
      }
    }
  }
  //.head3{
  //  width: 4vw;
  //}
  .head4{
    margin-left: -3vw;
    width: 32vw;
    span{
      font-size: .9vw;
      color: #32435C;
    }
  }
}
.vue-fixed-header--isFixed {
  left: 0;
  top: 0;
  right: 0;
  z-index: 999;
  position: fixed;
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
  animation: 900ms cubic-bezier(0.2, 1, 0.22, 1) 0s normal none 1 running
    fadeInDown;
}
.myHead{

  a{
    font-size: 1vw;
    margin-left: 3vw;
  }
  .concat{
    width: 36vw;
    height: 4vw;
    line-height: 4vw;
    padding-left: 20vw;
    img{
      margin-left: 1.5vw;
      cursor: pointer;
    }
  }
}
.header-navigation--header-default .header-navigation__nav nav > ul > li > a {
  /* padding: 25px 0; */
  color: #ffffff;
}

@media screen and (max-width: 700px) {
  .header-info-wrapper{
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
  }
  .header-Search,.header-contact{
    display: none;
  }
}
.mobile-navigation-icon {
  width: 24px;
  height: 25px;
  position: relative;
  cursor: pointer;
  left: 100px;
}
.mobile-navigation-icon i{
  width: 5%;
}
</style>
