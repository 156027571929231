<template>
  <div class="record">
    <p class="to" @click="toD">【上海智见略同教育科技有限公司】
    备案号：沪ICP备2023008396号-2号</p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Record",
  methods:{
    toD(){
      window.open('https://beian.miit.gov.cn/#/Integrated/index',"_blank");
    }
  }
}
</script>

<style scoped>
*{
  padding: 0;
  margin: 0;
}
.record{
  text-align: center!important;
  padding: 10px 5px;
  background-color:#1d3d59;
  color: white;
}
.to{
  cursor: pointer;
}
</style>