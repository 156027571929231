<template >
  <section class="form-teacher-view">
		<div class="container">
			<div class ='row'>
				<!-- 获取留学计划 -->
				<div  class="form-container col-lg-4 col-12">
					<div class="main-title">获取留学方案</div>
					<div class="divider"></div>
					<b-form class="form-body" @submit="onSubmit">
						<div v-if="submit">
              <b-form-group class="form-field" id="form-name">
                <b-form-input
                    id="form-name-input"
                    v-model="form.name"
                    placeholder="请填写您的姓名"
                    required
                ></b-form-input>
              </b-form-group>

              <b-form-group class="form-field" id="form-wechat">
                <b-form-input
                    id="form-wechat-input"
                    v-model="form.wechat"
                    placeholder="您的微信号"
                    required
                ></b-form-input>
              </b-form-group>

              <b-form-group class="form-field" id="form-highest_qualification">
                <b-form-select
                    id="form-stage-input"
                    v-model="form.highest_qualification"
                    :options="highest_qualification"
                    required
                ></b-form-select>
              </b-form-group>

              <b-form-group class="form-field" id="form-estimated_date">
                <b-form-select
                    id="form-estimate-time-input"
                    v-model="form.estimated_date"
                    :options="estimated_date"
                    required
                ></b-form-select>
              </b-form-group>

              <b-form-group class="form-field" id="form-requirement">
                <b-form-textarea
                    id="form-requirement"
                    v-model="form.requirement"
                    placeholder="您的留学需求"
                    required
                ></b-form-textarea>
              </b-form-group>

              <div class="btn-wrapper">
                <b-button class="submit-btn" type="submit" variant="primary">提交</b-button>
              </div>
            </div>

            <div class="col-12 order-1 order-lg-2" v-else>
              <h3 style="color: black; text-align: center; padding-top: 30px">正在提交</h3>
              <div style="padding-top: 70px">
                <b-icon style="
					color: black;
					display: block;
					margin-left: auto;
					margin-right: auto;
				" icon="truck" animation="cylon" font-scale="4" shift-v="0"></b-icon>
                <b-icon style="
					color: black;
					display: block;
					margin-left: auto;
					margin-right: auto;
				" icon="three-dots" animation="cylon" font-scale="4"></b-icon>
              </div>
            </div>
					</b-form>

				</div>

				<!-- 老师名片 -->
				<div  class="teachers-info col-lg-8">
					<div class="main-title">老师名片</div>
					<div class="divider"></div>
          <div>
            <el-carousel :interval="3000" indicator-position="outside" height="38vw">
              <el-carousel-item indicator-position="none" v-for="item in hotSubjects" :key="item.id">
                <div style="display: flex;justify-content:space-around;padding: 4px .5vw">
                  							<TeacherCard class="tCard" v-for="item of item.subjects"
                                  :key="item.id"
                  								:avatar="item.avatar"
                  								position="职位：高级留学规划师（高等教育事业部）"
                  								:intro="item.introduction"
                  								consultUrl="#"
                  								:name="item.name"
                  								:sm="false"
                                  :qr="item.wechat_qr"
                  							/>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
<!--					<div >-->
<!--						<div class="teachers" >-->
<!--							<TeacherCard class="tCard" v-for="item of teacherData"-->
<!--                :key="item.id"-->
<!--								:avatar="item.avatar"-->
<!--								position="职位：高级留学规划师（高等教育事业部）"-->
<!--								:intro="item.introduction"-->
<!--								consultUrl="#"-->
<!--								:name="item.name"-->
<!--								:sm="false"-->
<!--                :qr="item.wechat_qr"-->
<!--							/>-->
<!--						</div>-->
<!--					</div>-->
				</div>
			</div>
		</div>

    <el-dialog
        title="提交成功"
        style="padding: 0 20px"
        :visible.sync="dialogVisible"
        width="30%">
      <div class="gwInfo">
        <div class="gw-left">
          <img width="60" height="60" src="../../assets/myImage/成功.png" alt="">
        </div>
        <div class="text">亲，我们已经收到您的信息！专业的顾问老师会火速与您联系～您也可以添加微信：<strong>EduSg11</strong> 或 <strong>扫码直接咨询我们的老师！</strong></div>
        <div class="er">
          <img width="210" height="200" src="../../assets/myImage/guwen.jpg" alt="">
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span>
    </el-dialog>
  </section>
</template>

<script>
		import TeacherCard from '../fragments/TeacherCard.vue'
    import {httpService} from "@/services/http";
    import {constants} from "@/services/constants";
    import { mondayService } from "@/services/monday";
    import axios from "axios";
    import {schoolService} from "@/services/school";
    import successCard from "@/components/SuccessCard/successCard.vue";

    export default {
      components:{
        successCard,
        TeacherCard,
      },
      data() {
				return {
          submit:true,
          dialogVisible: false,
          teacherData:'',
					form: {
						name: '',
            wechat: '',
            estimated_date: null,
            requirement: '',
            budget:'',
            phone:'',
            contact_time:'',
            highest_qualification:null,
					},
          highest_qualification: [
						{ value: null, text: "选择教育程度", disabled: true },
						{ value: "幼儿园", text: "幼儿园" },
						{ value: "小学", text: "小学" },
						{ value: "中学", text: "中学" },
						{ value: "高中", text: "高中" },
						{ value: "大学", text: "大学" },
						{ value: "研究生", text: "研究生" },
						{ value: "其他学历", text: "其他学历" },
					],
          estimated_date: [
						{ value: null, text: "输入您的计划留学时间", disabled: true },
						{ value: "三个月内", text: "三个月内" },
						{ value: "六个月内", text: "六个月内" },
						{ value: "一年内", text: "一年内" },
						{ value: "明年、之后", text: "明年、之后" },
					],
          budget : [
            { value: null, text: "输入您的计划留学预算", disabled: true },
            { value: '10万-20万人民币/年', text: '10万-20万人民币/年' },
            { value: '20-30万人民币/年', text: '20-30万人民币/年' },
            { value: '无指定要求', text: '无指定要求' },
          ],
					show: true,
          successStory: "",
          swiperOption: {
            slidesPerView: 4,
            slidesPerColumn: 2,
            width: '1900',
            // height: 1500,
            spaceBetween: 30,
            grabCursor: true,
            pagination: {
              el: '.swiper-pagination',
              clickable: true
            },
          },
          hotSubjects:[
            {id:1,subjects:[]},
            {id:2,subjects:[]},
            // {id:3,subjects:[]},
          ]
				}
			},
      created() {
        httpService.get(constants.sagepathsAgent).then((data)=>{//获取咨询老师信息
          this.teacherData = data.body.results
          console.log(this.teacherData)
          this.hotSubjects[0].subjects = data.body.results.slice(0,3)
          this.hotSubjects[1].subjects = data.body.results.slice(3,6)
          // console.log(this.teacherData)
        })
            // schoolService.getSuccessCase().then((data) => {
            //   console.log(data)
            //   this.hotSubjects[0].subjects = data.results.slice(0,4)
            //   this.hotSubjects[1].subjects = data.results.slice(4,8)
            //   // this.hotSubjects[2].subjects = data.results.slice(8,9)
            // })
      },
			methods: {
				onSubmit(event) {
					event.preventDefault()
          this.submit = false
          axios({
            method:'post',
            url:"https://158-liuxue-p.study65.com/api/postStudy65WebStudentEnquiry/",
            headers: {
              'content-type': 'application/json',
              'authorization': '92697bbf-bc6e-4b66-a446-89aed3f30b4f',
            },
            data: {
              ...this.form
            }
          }).then((res) => {
            this.dialogVisible = true//提交完成后
            this.submit = true//提交过程，等待结果返回之前展示小汽车
            this.form = {//提交完成后，清空表单
                  name: '',
                  wechat: '',
                  estimated_date: null,
                  requirement: '',
                  budget:'',
                  phone:'',
                  contact_time:'',
                  highest_qualification:null,
            },
            console.log(res)
          })
				},
			}
    }
</script>

<style lang="scss" scoped>
//*{
//  outline: 1px solid red;
//}

.container{
  max-width: 80%;
}
.form-teacher-container{

}
		.form-teacher-view {
      margin: 5vw auto;
			display: flex;
			.main-title {
				text-align: center;
				font-family: 'Inter';
				font-style: normal;
				font-weight: 400;
				font-size: 2vw;
				line-height: 3vw;
				color: #000000;
			}
			.divider {
				width: 80px;
				border-bottom: 4px solid #007DD1;
				margin: auto;
				margin-top: 30px;
				margin-bottom: 30px;
			}

			.form-container {
				.form-body {
					border: 1px solid #E7E7EA;
					box-shadow: 0px 5px 14px rgba(8, 15, 52, 0.04);
					border-radius: 20px;
					padding: 10px 30px;
				}

				.btn-wrapper {
					display: flex;
					justify-content: center;
					.submit-btn {
						width: 110px;
						background-color: #007DD1;
						padding: 10px 30px;
						border-radius: 8px;
					}
				}

				.form-field {

					margin: 40px 0;
					select, input {
						padding: 5px 20px;
						height: 50px;
					}

				}
			}
			.teachers{
				display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .tCard{
          margin-top: 2.5vw;
        }
			}
		}
.gwInfo{
  margin-top: 0;
  text-align: center;
}
.text{
  margin-top: .5vw;
}
    .gw{
      display: flex;
      justify-content: space-between;
      height: 14vw;
      margin-top: 1vw;
      padding: 0 1vw 2vw;

    }
    .er{
      margin-top: .7vw;
    }
.successful-cases-view {
  width: 90%;
  padding-top: 5vw;
  margin: 0 auto;
  .successful-cases-wrapper {
    display: flex;
    justify-content: center;
  }

  .main-title {
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 7vw;
    //line-height: 61px;
    color: #000000;
  }



  .swiper {
    margin: 30px;
    height: 80vw;


    .swiper-slide {
      height: 37vw;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
      border-radius: 10px;

      .img-wrapper {
        position: relative;
        height: 28vw;
        img.offerImg {
          width: 100%;
          height: 100%;
        }

        .tag {
          position: absolute;
          background-color: #FBB040;
          color: #FFF;
          right: 20px;
          top: 20px;
          width: 115px;
          text-align: center;
          font-family: 'DM Sans';
          font-size: 1vw;
          font-weight: 700;
          border-radius: 13px;
          padding: 6px 0;
        }

      }


      .desciption {
        display: flex;
        justify-content: space-between;
        margin-top: 1.5vw;
        width: 25vw;
        padding-left: 1vw;
        img.avatar {
          width: 4vw;
          height: 4vw;
        }

        .text-container {
          font-family: 'DM Sans';
          padding-left: -2vw;
          .school {
            font-size: 1.2vw;
            font-weight: 500;
            width: 15vw;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .major {
            color: #9D9D9D;
            font-size: .8vw;
            width: 18vw;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

          }
        }
      }
    }
  }

}
</style>
