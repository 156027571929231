import Vue from 'vue'
import { httpService } from './http'
import { constants } from './constants'

var schoolService = {
    getAllSchools: async function (type, params) {
        if (params == '' && type == '全部院校') {
            params = constants.SCHOOL_LIST
        } else if(params == '') {
            params = constants.SCHOOL_LIST + "?school_types__type=" + type
        }
        let returnData = await httpService.get(params)
        return returnData.data
    },
    getBlogs:async function () {
        let blogData = await httpService.get(constants.BLOGS)
        return blogData.body
    },
    getSchoolTypes: async function () {
        let returnData = await httpService.get(constants.SCHOOL_TYPE)
        return returnData.data
    },
    getSchoolData: async function (id) {
        let returnData = await httpService.get(constants.SCHOOL_DETAIL + id + "/")
        return returnData.data
    },
    getSchoolSubjects: async function (id){
        let returnData = await httpService.get(constants.SCHOOL_LIST + id + "/get_subjects/")
        return returnData.data.results
    },
    getAllSubjects: async function (params){
        let returnData = await httpService.get(constants.SUBJECT_LIST + params)
        return returnData.data
    },
    getMore: async function (url){
        let returnData = await httpService.get(url)
        return returnData.data
    },
    getSubjectTypes: async function (){
        let returnData = await httpService.get(constants.SUBJECT_TYPE)
        return returnData.data
    },
    getSubjectLevels: async function (){
        let returnData = await httpService.get(constants.SUBJECT_LEVEL)
        return returnData.data
    },
    getSubjectSubTypes: async function (){
        let returnData = await httpService.get(constants.SUBJECT_SUBTYPE)
        return returnData.data
    },
    getSuccessCase: async function(){
        let returnData = await httpService.get(constants.SUCCESS_CASE+'?ordering=-create_date')
        return returnData.data
    }
}

export {
    schoolService
};