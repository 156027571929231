import Vue from 'vue'
import VueRouter from 'vue-router'
import query from "vue-resource/src/url/query";

Vue.use(VueRouter)

const routes = [
    {
        path: '*',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "NotFound" */ '../views/404.vue')
    },
    {
        path: '/',
        name: 'HomeOne',
        component: () => import(/* webpackChunkName: "home-one" */ '../views/HomeOne.vue'),

    },
    {
        path: '/showSearch',
        name:'showSearch',
        component: () => import(/* webpackChunkName: "contact" */ '../views/showSearch')
    },
    {
        path: '/home-two',
        name: 'HomeTwo',
        component: () => import(/* webpackChunkName: "home-two" */ '../views/HomeTwo.vue'),
    },
    {
        path: '/home-three',
        name: 'HomeThree',
        component: () => import(/* webpackChunkName: "home-three" */ '../views/HomeThree.vue')
    },
    {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/schools',
        name: 'SchoolList',
        component: () => import(/* webpackChunkName: "service" */ '../views/SchoolList.vue'),
        props: route => ({ type: route.query.t })
    },
    {
        path: '/majorIntroduce',
        name: 'majorIntroduce',
        component: () => import(/* webpackChunkName: "majorIntroduce" */ '../views/majorIntroduce'),
        props: route => ({ type: route.query.t })
    },
    {
        path: '/service-details',
        name: 'ServiceDetails',
        component: () => import(/* webpackChunkName: "service-details" */ '../views/ServiceDetails.vue'),
        props: route => ({ type: route.query.id })
    },
    {
        path: '/service-details-right',
        name: 'ServiceDetailsRight',
        component: () => import(/* webpackChunkName: "service-details-right" */ '../views/ServiceDetailsRight.vue')
    },
    {
        path: '/project',
        name: 'Project',
        component: () => import(/* webpackChunkName: "project" */ '../views/Project.vue')
    },
    {
        path: '/k12/intro',
        name: 'smallAge',
        component: () => import(/* webpackChunkName: "project" */ '../views/smallAge.vue')
    },
    {
        path: '/web',
        name: 'web',
        component: () => import(/* webpackChunkName: "project" */ '../views/websok.vue')
    },
    {
        path: '/project-details',
        name: 'ProjectDetails',
        component: () => import(/* webpackChunkName: "project-details" */ '../views/ProjectDetails.vue')
    },
    {
        path: '/news',
        name: 'studyNews',
        component: () => import(/* webpackChunkName: "blog-left-sidebar" */ '../views/study/studyNews.vue')
    },
    {
        path: '/studyKnows',
        name: 'studyKnows',
        component: () => import(/* webpackChunkName: "blog-right-sidebar" */ '../views/study/studyKnows.vue')
    },
    {
        path: '/studyLife',
        name: 'studyLife',
        component: () => import(/* webpackChunkName: "blog-details" */ '../views/study/studyLife.vue')
    },
    {
        path: '/newslist',
        name: 'studyGo',
        component: () => import(/* webpackChunkName: "blog-details-right-sidebar" */ '../views/study/studyGo.vue')
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
    },
    {
        path: '/schools/schoolIntroduce',
        name:'schoolIntroduce',
        component: () => import(/* webpackChunkName: "contact" */ '../views/schoolIntroduce.vue')
    },
    {
        path: '/project/subMajors',
        name:'subMajors',
        component: () => import(/* webpackChunkName: "contact" */ '../views/subMajors')
    },
    {
        path: '/moreQuestions',
        name:'moreQuestions',
        component: () => import(/* webpackChunkName: "contact" */ '../views/moreQuestions')
    },
    // {
    //     path: '/',
    //     name: 'top3School',
    //     component: () => import(/* webpackChunkName: "home-one" */ '../components/top3/top3School'),
    //     meta:{
    //         keepAlive:true
    //     }
    // },

]
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
