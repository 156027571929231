import Vue from 'vue'
import App from './App.vue'
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import router from './router'
import VueMeta from 'vue-meta'
import VueSilentbox from 'vue-silentbox'
import BackToTop from 'vue-backtotop'
import axios from 'axios'
import * as VueGoogleMaps from 'vue2-google-maps'
import OwlCarousel from 'v-owl-carousel'
import { constants } from './services/constants';
import Floating from 'vue-floating';
import VueResource from 'vue-resource';
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
import store from './store/index'
vue.http.headers.common['Authorization'] = '92697bbf-bc6e-4b66-a446-89aed3f30b4f'


Vue.use(ElementUI)

ElementUI.Dialog.props.lockScroll.default = false;







import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

Vue.use(mavonEditor)
Vue.use(VueResource);
Vue.prototype.$baseUrl = constants.BASE_URL
Vue.component('carousel', OwlCarousel)
Vue.use(Floating);
Vue.use(VueSilentbox)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(BackToTop)
Vue.use(VueAwesomeSwiper)
Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
})
import BaiduMap from 'vue-baidu-map'

Vue.use(BaiduMap, {

    ak:'NW4lXEkVN2XvfrDQ4NkVkPffoBfQLGhx'
})

Vue.use(VueGoogleMaps, {
    load: {
      apiKey: 'AIzaSyCaMV8mRW8VSNfraGyxlJb7XawkZYEYLm4',
      
      //libraries: ['...']
    },
    installComponents: true
  })
Vue.prototype.$axios = axios

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../src/assets/css/font-awesome.min.css'
import '../src/assets/css/material-design-iconic-font.min.css'
import '../src/assets/css/ionicons.min.css'
import '../src/assets/css/flaticon.min.css'
import 'swiper/css/swiper.min.css'
import '../src/assets/css/animate.min.css'
import '../src/assets/scss/style.scss'
import vue from "vue";

Vue.config.productionTip = false

router.afterEach((to, from, next) => {//所有跳转后默认展示顶部

    window.scrollTo(0, 0)

});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
