import Vue from 'vue'
import axios from 'axios'


// var mondayService = {
//     headers: {
//         'content-type': 'application/json',
//         Authorization: 'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjkzNDM4NjA2LCJ1aWQiOjE1MzI1NjAxLCJpYWQiOiIyMDIwLTEyLTE0VDA5OjI1OjA4LjAwMFoiLCJwZXIiOiJtZTp3cml0ZSJ9.HSyH0IcIpP0T4QN27n0WKVZU7jzJ-ohSDYlQC280vBM',
//     },
//     post: async function (body) {
//         return new Promise((resolve, reject) => {
//             axios
//                 .post("https://api.monday.com/v2", body,{headers:this.headers})
//                 .then(response => {
//                     resolve(response)
//                 })
//                 .catch(error => {
//                     reject(error)
//                 })
//         })
//
//     },
//     createUpdate: async function (body) {
//         return new Promise((resolve, reject) => {
//             axios
//                 .post("https://api.monday.com/v2", body, {headers:this.headers})
//                 .then(response => {
//                     resolve(response)
//                 })
//                 .catch(error => {
//                     reject(error)
//                 })
//         })
//
//     },
// }
var mondayService = {
    headers: {
        'content-type': 'application/json',
        Authorization: '92697bbf-bc6e-4b66-a446-89aed3f30b4f',
    },
    post: async function (body) {
        return new Promise((resolve, reject) => {
            axios
                .post("https://158-liuxue-p.study65.com/postMpStudentEnquiry//", body,{headers:this.headers})
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })

    },
    createUpdate: async function (body) {
        return new Promise((resolve, reject) => {
            axios
                .post("https://api.monday.com/v2", body, {headers:this.headers})
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })

    },
}

export {
    mondayService
};