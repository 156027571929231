<template>
  <div id="app">

    <div class="bar">
<!--      <div :class="{'bg1':show1}" @mouseenter="show('show1')" @mouseleave="showHide()">-->
<!--        <div class="img">-->
<!--          <img height="100%" width="100%" src="../public/assets/img/咨询2.png" alt="">-->
<!--        </div>-->
<!--        <div class="myText">在线咨询</div>-->
<!--        <hr class="myHr">-->
<!--      </div>-->
      <div :class="{'bg1':show2}" @mouseenter="show('show2')" @mouseleave="showHide()">
        <div class="img">
          <img height="100%" width="100%" src="../public/assets/img/编辑文件2.png" alt="">
        </div>
        <div class="myText">免费评估</div>
        <hr class="myHr">
      </div>
      <div :class="{'bg1':show3}" @mouseenter="show('show3')" @mouseleave="showHide()">
        <div class="img">
          <img height="100%" width="100%" src="../public/assets/img/微信公众号.png" alt="">
        </div>
        <div class="myText">微信公众号</div>
        <hr class="myHr">
      </div>
      <div :class="{'bg1':show4}" @mouseenter="show('show4')" @mouseleave="showHide()">
        <div class="img">
          <img height="100%" width="100%" src="../public/assets/img/微信小程序.png" alt="">
        </div>
        <div class="myText">微信小程序</div>
        <hr class="myHr">
      </div>
      <div :class="{'bg1':show5}" @mouseenter="show('show5')" @mouseleave="showHide()">
        <div class="img">
          <img height="100%" width="100%" src="../public/assets/img/微信(1).png" alt="">
        </div>
        <div class="myText">微信咨询</div>
        <hr class="myHr">
      </div>
      <div :class="{'bg1':show6}" @mouseenter="show('show6')" @mouseleave="showHide()">
        <div class="img">
          <img height="100%" width="100%" src="../public/assets/img/咨询3.png" alt="">
        </div>
        <div class="myText">电话咨询</div>
      </div>
    </div>
<!--    <transition name="el-fade-in-linear">-->
<!--    <div class="showBar1" v-show="show1" @mouseenter="show('show1')" @mouseleave="showHide()">在线咨询</div>-->
<!--    </transition>-->

    <transition name="el-fade-in-linear">
    <div class="showBar2" v-show="show2" @mouseenter="show('show2')" @mouseleave="showHide()">
      <b-form class="form-body" @submit="onSubmit">
        <div v-if="submit">
          <h4 style="text-align: center">获取留学方案</h4>
          <div class="divider"></div>
          <b-form-group class="form-field" id="form-name">
            <b-form-input
                class="myIput"
                id="form-name-input"
                v-model="form.name"
                placeholder="请填写您的姓名"
                required
            ></b-form-input>
          </b-form-group>

          <b-form-group class="form-field" id="form-wechat">
            <b-form-input
                class="myIput"
                id="form-wechat-input"
                v-model="form.wechat"
                placeholder="您的微信号"
                required
            ></b-form-input>
          </b-form-group>

          <b-form-group class="form-field" id="form-highest_qualification">
            <b-form-select
                class="myIput"
                id="form-stage-input"
                v-model="form.highest_qualification"
                :options="highest_qualification"
                required
            ></b-form-select>
          </b-form-group>

          <b-form-group class="form-field" id="form-estimated_date">
            <b-form-select
                class="myIput"
                id="form-estimate-time-input"
                v-model="form.estimated_date"
                :options="estimated_date"
                required
            ></b-form-select>
          </b-form-group>

          <b-form-group class="form-field" id="form-requirement">
            <b-form-textarea
                class="myIput"
                id="form-requirement"
                v-model="form.requirement"
                placeholder="您的留学需求"
                required
            ></b-form-textarea>
          </b-form-group>

          <div class="btn-wrapper">
            <b-button class="submit-btn" type="submit" variant="primary" size="mini">提交</b-button>
          </div>
        </div>

        <div class="col-12 order-1 order-lg-2" v-else>
          <h3 style="color: black; text-align: center; padding-top: 30px">正在提交</h3>
          <div style="padding-top: 70px">
            <b-icon style="
					color: black;
					display: block;
					margin-left: auto;
					margin-right: auto;
				" icon="truck" animation="cylon" font-scale="4" shift-v="0"></b-icon>
            <b-icon style="
					color: black;
					display: block;
					margin-left: auto;
					margin-right: auto;
				" icon="three-dots" animation="cylon" font-scale="4"></b-icon>
          </div>
        </div>
      </b-form>
    </div>
    </transition>

    <transition name="el-fade-in-linear">
    <div class="showBar3" v-show="show3" @mouseenter="show('show3')" @mouseleave="showHide()">
      <img width="200px" title="微信公众号" src="../public/assets/img/gzh.jpg">
    </div>
    </transition>
    <transition name="el-fade-in-linear">
    <div class="showBar4" v-show="show4" @mouseenter="show('show4')" @mouseleave="showHide()">
      <img width="200px" title="微信小程序" src="../public/assets/img/miniprogram.png">
    </div >
    </transition>

    <transition name="el-fade-in-linear">
    <div class="showBar5" v-show="show5" @mouseenter="show('show5')" @mouseleave="showHide()">
      <img width="200px" title="微信咨询" src="./assets/myImage/guwen.jpg">
    </div>
    </transition>

    <transition name="el-fade-in-linear">
    <div class="showBar6" v-show="show6" @mouseenter="show('show6')" @mouseleave="showHide()">
      <div style="text-align: center">
        <div>请拨打下方电话资讯顾问老师！</div>
        <div><strong>电话：+86 13816876870</strong></div>
        <div>
          <img src="./assets/myImage/ter.jpeg" width="200" height="180" alt="">
        </div>
      </div>
    </div>
    </transition>

    <el-dialog
        title="提交成功"
        style="padding: 0 20px"
        :visible.sync="dialogVisible"
        width="30%">
      <div class="gwInfo">
        <div class="gw-left">
          <img width="60" height="60" src="./assets/myImage/成功.png" alt="">
        </div>
        <div class="text">亲，我们已经收到您的信息！专业的顾问老师会火速与您联系～您也可以添加微信：<strong>EduSg11</strong> 或 <strong>扫码直接咨询我们的老师！</strong></div>
        <div class="er">
          <img width="210" height="200" src="./assets/myImage/guwen.jpg" alt="">
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span>
    </el-dialog>


    <Header></Header>
    <router-view />
    <FormTeacherGroup></FormTeacherGroup>
    <!-- back to top start -->
    <back-to-top class="scroll-top" bottom="60px">
      <i class="ion-android-arrow-up"></i>
    </back-to-top>
    <!-- back to top end -->
<!--    <div class="footer-area dark-bg">-->
<!--      <div class="row">-->
<!--        <img width="100%" height="100%" src="../src/assets/myImage/Group.png">-->
<!--      </div>-->
<!--    </div>-->
    <div class="foot-content">
      <div class="content1">
        <div class="myT">联系我们</div>
        <div class="content1-c">
          <div class="myimg">
            <img width="100%" src="../public/assets/img/导航地址2.png" alt="">
          </div>
          <div class="myconcat">
            <div>新加坡留学网（上海）</div>
            <div>电话：+86&nbsp;13816876870</div>
            <div>地址：上海市浦东区银城中路68号时代金融中心3302</div>
          </div>
        </div>
        <div class="content1-c">
          <div class="myimg">
            <img width="100%" src="../public/assets/img/导航地址2.png" alt="">
          </div>
          <div class="myconcat">
            <div>新加坡留学网（新加坡总部）</div>
            <div>SAGEPATHS GLOBAL EDUCATION PTE.LTD.</div>
            <div>电话：+65&nbsp;60150667</div>
            <div>地址：1 North Bridge Road #06-01</div>
          </div>
        </div>
      </div>
      <div class="content2">
        <div>关于我们</div>
        <ul>
          <li style="list-style-type: none">
            <img src="../public/assets/img/footLogo/新加坡留学网200.png" alt="">
            <a target="_blank" href="https://sagepaths.com">留学网</a>
          </li>
          <li style="list-style-type: none">
            <img src="../public/assets/img/footLogo/douyin200.png" alt="">
            <a target="_blank" href="https://www.douyin.com/user/MS4wLjABAAAASw2ybb7cs7vAf3CP4JqCKAQrjCrrx4MB5Y0bWSwn2kVFeKPHhD_DJfPBdJWzLbbu?showTab=post">抖音</a>
          </li>
          <li style="list-style-type: none">
            <img src="../public/assets/img/footLogo/weibonew.png" alt="">
            <a target="_blank" href="https://m.weibo.cn/u/6647474879?uid=6647474879&t=0&luicode=10000011&lfid=100103type%3D1%26q%3D%E6%96%B0%E5%8A%A0%E5%9D%A1%E6%99%BA%E7%95%A5%E5%9B%BD%E9%99%85%E6%95%99%E8%82%B2">微博</a>
          </li>
          <li style="list-style-type: none">
            <img src="../public/assets/img/footLogo/zhihu200.png" alt="">
            <a target="_blank" href="https://www.zhihu.com/org/158liu-xue">知乎</a>
          </li>
        </ul>
      </div>
      <div class="content3">
        <ul>
          <li style="list-style-type: none"><a target="_blank" href="https://study65.com/">首页</a></li>
          <li style="list-style-type: none"><a target="_blank" href="https://study65.com/schools?t=%E5%85%AC%E7%AB%8B%E5%AD%A6%E9%99%A2">院校推荐</a></li>
          <li style="list-style-type: none"><a target="_blank" href="https://study65.com/project">专业筛选</a></li>
          <li style="list-style-type: none"><a target="_blank" href="https://study65.com/studyNews">热点新闻</a></li>
          <li style="list-style-type: none"><a target="_blank" href="https://study65.com/contact">关于我们</a></li>
          <li style="list-style-type: none"><a target="_blank" href="https://study65.com/k12/intro">低龄留学</a></li>
        </ul>
      </div>
      <div class="content4">
        <div class="myT">微信公众号</div>
        <div class="myimg">
          <img width="100%" src="../public/assets/img/gzh.jpg" alt="">
        </div>
      </div>
      <div class="content4">
        <div class="myT">微信小程序</div>
        <div class="myimg">
          <img width="100%" src="../public/assets/img/miniprogram.png" alt="">
        </div>
      </div>
    </div>
    <Record></Record>
  </div>
</template>

<script>
import OffCanvasMobileMenu from "@/components/OffCanvasMobileMenu";
import Header from "@/components/Header";
import FormTeacherGroup from "@/components/sections/FormTeacherGroup";
import axios from "axios";
import Record from "@/components/sections/Record.vue";

export default {
  components:{
    Header,
    OffCanvasMobileMenu,
    FormTeacherGroup,
    Record
  },
  data() {
    return {
      show1:false,
      show2:false,
      show3:false,
      show4:false,
      show5:false,
      show6:false,
      submit:true,
      dialogVisible: false,
      teacherData:'',
      form: {
        name: '',
        wechat: '',
        estimated_date: null,
        requirement: '',
        budget:'',
        phone:'',
        contact_time:'',
        highest_qualification:null,
      },
      highest_qualification: [
        { value: null, text: "选择教育程度", disabled: true },
        { value: "幼儿园", text: "幼儿园" },
        { value: "小学", text: "小学" },
        { value: "中学", text: "中学" },
        { value: "高中", text: "高中" },
        { value: "大学", text: "大学" },
        { value: "研究生", text: "研究生" },
        { value: "其他学历", text: "其他学历" },
      ],
      estimated_date: [
        { value: null, text: "输入您的计划留学时间", disabled: true },
        { value: "三个月内", text: "三个月内" },
        { value: "六个月内", text: "六个月内" },
        { value: "一年内", text: "一年内" },
        { value: "明年、之后", text: "明年、之后" },
      ],
      budget : [
        { value: null, text: "输入您的计划留学预算", disabled: true },
        { value: '10万-20万人民币/年', text: '10万-20万人民币/年' },
        { value: '20-30万人民币/年', text: '20-30万人民币/年' },
        { value: '无指定要求', text: '无指定要求' },
      ],
      myShow: true
    };
  },
  methods: {
    showGZH(id) {
      this.hideRqCode();

      if (id == "ZFB") {
        this.ZFB = true;
      } else if (id == "GZH") {
        this.GZH = true;
      } else {
        this.WX = true;
      }
    },
    hideRqCode() {
      this.GZH = false;
      this.WX = false;
      this.ZFB = false;
    },
    show(show){
      switch (show) {
        case 'show1':this.show1 = true
              break
        case 'show2':this.show2 = true
              break
        case 'show3':this.show3 = true
              break
        case 'show4':this.show4 = true
              break
        case 'show5':this.show5 = true
              break
        case 'show6':this.show6 = true
              break
      }
    },
    showHide(){
      this.show1 = false
      this.show2 = false
      this.show3 = false
      this.show4 = false
      this.show5 = false
      this.show6 = false
    },
    onSubmit(event) {
      event.preventDefault()
      this.submit = false
      axios({
        method:'post',
        url:"https://158-liuxue-p.study65.com/api/postStudy65WebStudentEnquiry/",
        headers: {
          'content-type': 'application/json',
          'authorization': '92697bbf-bc6e-4b66-a446-89aed3f30b4f',
        },
        data: {
          ...this.form
        }
      }).then((res) => {
        this.dialogVisible = true//提交完成后
        this.submit = true//提交过程，等待结果返回之前展示小汽车
        this.form = {//提交完成后，清空表单
          name: '',
          wechat: '',
          estimated_date: null,
          requirement: '',
          budget:'',
          phone:'',
          contact_time:'',
          highest_qualification:null,
        },
            console.log(res)
      })
    },
  },
};
</script>


<style lang="scss">
#app{
  overflow: hidden;
}
*{
  padding: 0;
  margin: 0;
}
ul{

  list-style-type: disc!important;
}

.form-body {
  border: 1px solid #E7E7EA;
  box-shadow: 0px 5px 14px rgba(8, 15, 52, 0.04);
  border-radius: 20px;
  padding: 20px 30px;
}
.myIput{
  font-size: 13px !important;
}
.gwInfo{
  margin-top: 0;
  text-align: center;
}
.btn-wrapper{
  text-align: center;
}
.text{
  margin-top: .5vw;
}
.gw{
  display: flex;
  justify-content: space-between;
  height: 14vw;
  margin-top: 1vw;
  padding: 0 1vw 2vw;

}
.er{
  margin-top: .7vw;
}
.bg1{
  font-size: 23px !important;
}
.bar{
  position: fixed;
  right: 0;
  top:10vw;
  z-index:999;
  width: 5vw;
  padding: .7vw 0;
  background-color: #007DD1;
  text-align: center;
  border-radius: 10px 0 0 10px;
}
.img{
  height: 2vw;
  width: 2vw;
  margin: 0 auto;
  cursor: pointer;
}
.myText{
  color: white;
  font-size: 10px;
  margin-top: .2vw;
  cursor: pointer;
}
.myHr{
  background-color: white;
  margin: .5vw auto;
  position: relative;
  width: 3.5vw;
  opacity: .7;
}
.showBar1{
  position: fixed;
  top:10vw;
  right: 5vw;
  z-index: 999;
  width: 100px;
  height: 100px;

  background-color: whitesmoke;
}
.showBar2{
  position: fixed;
  top:14.5vw;
  right: 5vw;
  z-index: 999;
  background-color: whitesmoke;
  border-radius: 20px;
}
.showBar3{
  position: fixed;
  top:19vw;
  right: 5vw;
  z-index: 999;
  background-color: #0C9DBF;
}
.showBar4{
  position: fixed;
  top:23.5vw;
  right: 5vw;
  z-index: 999;
  background-color: #0C9DBF;
}
.showBar5{
  position: fixed;
  top:27vw;
  right: 5vw;
  z-index: 999;
  background-color: #0C9DBF;
}
.showBar6{
  position: fixed;
  top:31vw;
  right: 5vw;
  z-index: 999;
  background-color: whitesmoke;
  padding: 1vw;
}
.main-title{
  font-size: 35px;
  text-align: center;
  margin-top: 40px;
}
.driver{
  height: 4px;
  text-align: center;
  width: 50px;
  background-color:#007DD1 ;
  margin: 20px auto;
}
.myBtn{
  margin: 30px auto;
  background-color: #007DD1;
  text-align: center;
  width: 150px;
  border-radius: 30px;
  height: 45px;
  color: white;
  font-size: 13px;
  line-height: 45px;
  cursor: pointer;
}

//*{
//  outline: 1px solid red;
//}
.anchorBL{
  display: none!important;
}
.BMap_cpyCtrl {

  display: none!important;

}
.foot-content{
  width: 100%;
  padding: 3vw 13vw 2vw 13vw;
  background: rgb(0, 125, 209);
  display: flex;
  justify-content: space-between;
  .content1{
    width: 40%;
    .myT{
      color: white;
      font-size: 1vw;
      padding-bottom: 1vw;
    }
    .content1-c{
      display: flex;
      align-items: center;
      .myimg{
        width: 8%;
        padding-bottom: 2vw;
      }
      .myconcat{
        color: white;
        font-size: 1vw;
        padding: 0 0 2vw 1vw;
      }
    }
  }
  .content2{
    margin-left: 1vw;
    width: 15%;
    font-size: 1vw;
    color: white;
    li{
      margin-top: 1.5vw;
      img{
        width: 16%;
      }
      a{
        padding-left: .5vw;
      }
    }
  }
  .content3{
    width: 15%;
    font-size: 1vw;
    color: white;
    li{
      margin-bottom: 1vw;
    }
  }
  .content4{
    width: 15%;
    font-size: 1vw;
    color: white;
    .myT{
      padding-bottom: .5vw;
    }
    .myimg{
      width: 60%;
    }
  }
}
.fix-vrm-bar {
  position: fixed;
  top: 500px;
  right: 10px;
  z-index: 999;
}
.fix-vrm-bar-up {
  position: fixed;
  z-index: 999;
}
.fix-vrm-bar button {
  height: 54px;
  width: 54px;
  font-size: 15px;
  background-color:#2B63AE ;
  color: #ffffff;
}

.fix-vrm-bar2 {
  position: fixed;
  top: 500px;
  right: 230px;
  z-index: 999;
}
@media screen and (max-width: 500px) {
  .fix-vrm-bar button{
    width: 45px;
    height: 45px;
    font-size: 10px;
  }
}


</style>>


